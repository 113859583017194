const awsmobile = {
    aws_project_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:be904a3f-ae4b-49f7-9e12-9e8a682f2f48',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_s0e9dWv6D',
    aws_user_pools_web_client_id: '5daoqrl5pfl7dkp12bqggn7g9t',
    "oauth": {
        "domain": "test-arcault-lifeplan-auth.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.test.arcault.com/oauth2/idpresponse",
        "redirectSignOut": "https://app.test.arcault.com/logout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};
export default awsmobile;

