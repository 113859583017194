import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, HStack, Stack, Text } from '@arcault-ui/shared-components';
import { Badge } from '@mui/material';
import './TeamViewer.css';
import AddTeamMember from './AddTeamMember';

export const rolesConfig = [
  { name: "Executor", label: "Executor", multiple: false, link: '/profile/estate/executor' },
  { name: "HealthAgent", label: "Health Agent", multiple: false, link: '/profile/estate/healthcareproxy' },
  { name: "FinancialPowerOfAttorney", label: "Financial Power of Attorney", multiple: true, link: '/profile/estate/poa' },
  { name: "Beneficiaries", label: "Beneficiaries", multiple: true, link: '/profile/estate/beneficiaries'}
];

const Team = ({ navigate, contacts }) => {
  const [openAddMember, setOpenAddMember] = useState(false);
  const [openRole, setOpenRole] = useState(null);

  const matchedRoles = rolesConfig.reduce((acc, role) => {
    const matchedContacts = contacts.filter(contact => contact.tags?.includes(role.name));
//    if (matchedContacts.length || role.multiple) {
      acc.push({ role, contacts: matchedContacts });
 //   }
    return acc;
  }, []).slice(0, 6);

  return (
    <Card title="Team" className="team-container your-vault-card" footer={(
      <HStack variant="end"><Button onClick={() => navigate('/profile/contacts')} type="outline">View all</Button></HStack>
    )}>
      <AddTeamMember currentRole={openRole} isOpen={openAddMember} handleClose={setOpenAddMember}/>
      <Stack variant="stretch">
        <div className="team-members">
          {matchedRoles.map(({ role, contacts }) => (
            contacts.length > 0 ? contacts.map(contact => (
              <Card key={contact.id} className="team-member">
                <Stack variant="center">
                  <Avatar user={contact} />
                  <Text variant="body">{contact?.name}</Text>
                  <Text variant="caption">{role.label}</Text>
                </Stack>
              </Card>
            )) : (
              <Card key={role.name} onClick={() => {
                setOpenRole(role.name)
                setOpenAddMember(true)
              }} className="team-member ghost dot">
                <Stack variant="center">
                  <Avatar user={{}} />
                  <div className="role-label"><Text variant="caption">{role.label}</Text></div>
                </Stack>
              </Card>
            )
          ))}
        </div>
      </Stack>
    </Card>
  );
};

Team.propTypes = {
  // If there are additional props we need to accept, we should define them here.
};

export default Team;