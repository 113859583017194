import React, { useEffect, useState } from 'react';

import { Button, Card, Icon, Stack, Text } from '@arcault-ui/shared-components'
import InitialForm from './InitialForm'
import { useSettings } from '../../context/SettingsContext';
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined';

import NextStep from '../common/NextStep';

import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import { useNavigate } from 'react-router-dom';

// TODO - remove this and use the one from the context
const steps = [
  {
    name: "readyToStart",
    ask: "Let's Go!",
    type: "button",
  },
  {
    name: "fullName",
    ask: "What is your name?",
    tip: "Please provide your full name. Any format will do.",
    validation: {
      regex: /^[a-zA-Z\s]+$/,
      message: "Please enter a valid name."
    },
    type: "text",
  },{
    name: "phone",
    ask: "What is your phone number?",
    tip: "Please provide a contact number",
    type: "text",
  },{
    name: "birthday",
    ask: "What is your birthday?",
    tip: "Please provide your birthday",
    type: "text",
  }
]

const BASIC_INFO = 'welcome';


const Home = (props) => {
  const [nextStep, setNextStep] = useState();
  const [activeJourney, setActiveJourney] = useState(0);
  const [basicInfoComplete, setBasicInfoComplete] = useState(false);

  const axiosWithCredentials = useAxiosWithCredentials();
  const navigate = useNavigate();

  const loadAvailableUserJourneys = () => { // remember to async when real
    return [BASIC_INFO];
  }

  const checkAndRunComplete = (stepId) => {
    if (stepId === 'available_journeys') {
      setBasicInfoComplete(true);
    }
  }

  const getDataAgent = async (journeyName) => {
    try {
      const nextData = await axiosWithCredentials.get('/ajourney/datagent/' + journeyName);
      checkAndRunComplete(nextData.data.stepId)
      setNextStep(nextData.data);
    } catch (error) {
      console.error("Failed to load user journey:", error);
    }
  }


  useEffect(() => {
    const journeys = loadAvailableUserJourneys();
    if(journeys && (journeys || []).includes(BASIC_INFO)) {
      getDataAgent(BASIC_INFO);
    } else {
      setBasicInfoComplete(true);
    }
  }, []);

  const saveData = async (propertyValue, step) => {
    const payload = {
      stepId: nextStep.stepId,
      propertyName: nextStep.propertyName,
      propertyValue
    };

    if (step.type === 'ai-generated') {
      payload.ai = true;
    }

    console.log('saveData', payload);
    const savedData = await axiosWithCredentials.post('/ajourney/datagent/welcome', payload);
    setNextStep(savedData.data);
    checkAndRunComplete(savedData.data.stepId)
    return savedData;
  }

  return (
    <>
      {/*(settings?.views?.splashScreenIndex && settings?.views?.splashScreenIndex !== 'finish') && <InitialForm
        isOpen={(settings?.views?.splashScreenIndex && settings?.views?.splashScreenIndex !== 'finish')}
        basicInfo={basicInfo}
        updateBasicInfo={updateBasicInfo}
        settings={settings}
        updateSettings={updateSettings}
      />*/}
      <Card className={`overview-inner overview-inner-${activeJourney}`}>
        <div style={{ width: 550, margin: 'auto', paddingBottom: 12, paddingTop: 8 }}>
          <img style={{ width: 40 }} src="logo_flame.png" alt="overview" className="overview-image" />
        </div>
        {!basicInfoComplete && (
          <>
            <div className="main-dashboard-card">
              <div style={{ textAlign: 'left', width: '100%' }}>
                <Text variant="h2">Welcome to Arcault</Text>
              </div>

              <Text variant="body">
                Let's get started by quickly gathering some basic information that will allow us to advise you better in all facets of life-planning.  This phase typically takes <em>less than a minute.</em>
              </Text>
            </div>

            <div className="next-step-container">
              <NextStep nextStep={nextStep} setNextStep={setNextStep} saveData={saveData}/>
            </div>
          </>
        )}
        {basicInfoComplete && (
            <div className="main-dashboard-card">
            <Text variant="h2">Welcome, Matthew!</Text>
            <Text variant="h3">How do you want to build your legacy today?</Text>
            <Text variant="body">We recommend you start with <b className="accent-text">Estate Planning.</b> Estate planning is the cornerstone of building a meaningful legacy. It ensures your assets, wishes, and values are protected and passed on exactly as you envision.</Text>
            <div className="choices-container">
              <Card className="estate-planning-choice" title="Estate Planning">
                <Stack variant="center">
                  <div className="estate-planning-image">
                    <FamilyRestroomOutlinedIcon />
                  </div>
                  <Button type="secondary" onClick={() => navigate('/estate')}>Let's Go</Button>
                </Stack>
              </Card>
            </div>
        </div>
        )}
      </Card>
    </>
  );
}

export default Home;