import React, { useState, useEffect } from 'react';
import { Autocomplete, Button, Icon, FormInput, Text, HStack, Stack } from '@arcault-ui/shared-components';
import { CircularProgress } from '@mui/material'

import { useHelp } from '../../context/HelpContext';
import useEnhancedInput from '../../hooks/useEnhancedInput';

const textTypes = ['text', 'email', 'phone', 'secret', 'money', 'ai-generated'];

const Step = ({ index, step, saveData }) => {
    const [value, setValue, forcedValue, isValid] = useEnhancedInput({ initialValue: step?.initial || '', type: step?.type });
    const [showOverlay, setShowOverlay] = useState(false);
    const [showCheckMark, setShowCheckMark] = useState(false);


    const handleKeyPress = async (e) => {
        console.log('key press', e.key, isValid, forcedValue);
        if (e.key === 'Enter' && isValid && forcedValue) {
            setShowOverlay(true);
            await saveData(forcedValue, step);
            setShowCheckMark(true);
        }
    };

    const { setHelpPrompt } = useHelp();

    const handleInputChange = (e) => {
      const eValue = e.target.value;
      setValue(eValue);
    }

    const handleClick = (e) => {
        saveData(e)
    }

    const { type } = step || {};

    return (
        <div id={`step-${index}`} className="step-container">
            {(showOverlay) && (
                <div className="overlay">
                    <CircularProgress color="success" />
                </div>
            )}
            {showCheckMark && (
                <div className="overlay">
                    <Icon name="Check" />
                </div>
            )}
            <Stack variant="center" className="estate-next-step-inner">
                {(type === "boolean") && (
                    <>
                        <Text variant="body">{step.ask || step.description}</Text>
                        <HStack className="button-group" variant="start">
                            <Button onClick={() => handleClick('Yes')} type="secondary">Yes</Button>
                            <Button onClick={() => handleClick('No')} type="outline">No</Button>
                        </HStack>
                    </>
                )}
                {(type === "button") && (
                    <div>
                        <Button onClick={handleClick} type="secondary">{step.ask}</Button>
                    </div>
                )}

                {(type === "date") && (
                    <FormInput
                        autoFocus
                        rightHelp={forcedValue && isValid && 'Enter to save.'}
                        data-testid="arcault-input"
                        label={step.ask || step.description}
                        value={forcedValue}
                        type={'text'}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                    />
                )}

                {(type === "select") && (
                    <Autocomplete
                        autoFocus
                        rightHelp={forcedValue && isValid && 'Enter to save.'}
                        placeholder={'Type to filter'}
                        suggestions={step.options}
                        data-testid="arcault-input"
                        label={step.ask || step.description}
                        value={forcedValue}
                        type={type}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                    />
                )} 
                
                {textTypes.includes(step?.type) && (
                    <FormInput
                        autoFocus
                        rightHelp={forcedValue && isValid && 'Enter to save.'}
                        data-testid="arcault-input"
                        label={step.ask === 'What is your middle name, full legal name, phone number, and birthday?' ?
                            'What\'s your full legal name, phone number, and birthday?' :
                            (step.ask || step.description)
                        }
                        value={forcedValue}
                        type={type === 'ai-generated' ? 'textarea' : type}    
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}/>
                )}
                {step?.description && step?.ask && <Text variant="body">{step?.description} {step?.explainer} <span className="help-link" onClick={() => setHelpPrompt(step.tip)}>Questions?</span></Text>}
            </Stack>
        </div>
    )
}

const NextStep = ({ nextStep, saveData }) => {
    const [steps, setSteps] = useState([]);

    useEffect(() => {
        const newSteps = [...steps, nextStep]
        setSteps(newSteps);
    }, [nextStep])

    useEffect(() => {
        if (steps.length > 0) {
            document.getElementById(`step-${steps.length - 1}`).scrollIntoView({ behavior: 'smooth' });
        }
    }, [steps])

    return (
        <>
        {steps.length > 0 && steps.map((step, index) => (
            <Step index={index} step={step} saveData={saveData} />
        ))}
        </>
    );
};

export default NextStep;