
import React from 'react';
import PropTypes from 'prop-types';
import { Progress, Card } from '@arcault-ui/shared-components';
import './EstateProgress.css'; // Assuming you have a CSS file for additional styles

const EstateProgress = ({ progress }) => {
    // Validating the progress value to be between 0 and 100
    if (typeof progress !== 'number' || progress < 0 || progress > 100) {
      console.error("Invalid 'progress' value. It should be a number between 0 and 100.");
      return null;
    }
  
    return (
        <Card className="estate-progress card-no-shadow">
            <div className="progress-container">
                <div className="progress-label">Progress</div>
                <div className="progress-amt">{progress}%</div>
                <Progress 
                    variant="pointed-right" 
                    height="64px"
                    color="accent"
                    value={progress} 
                    max={100}
                />
            </div>
        </Card>
    );
  };
  
  // Defining PropTypes for our component
  EstateProgress.propTypes = {
    // progress should be a number between 0 and 100
    progress: PropTypes.number.isRequired,
  };
  
  // Default Props
  EstateProgress.defaultProps = {};
  
  export default EstateProgress;