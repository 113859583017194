import React, {useState, useEffect}from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, HStack, Stack, Text } from  '@arcault-ui/shared-components';
import NextStep from './EstateNextStep';
import Progress from './EstateProgress';
import ProposalInbox from './ProposalInbox'
import DocumentsViewer from './DocumentsViewer';

import './EstateDashboard.css';
import EstateValueCard from './EstateValue';
import TeamViewer from './TeamViewer';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';

const EstateDashboard = ({action}) => {
  const [progress, setProgress] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [assets, setAssets] = useState([]);
  const [liabilities, setLiabilities] = useState([]);
  
  const axiosWithCredentials = useAxiosWithCredentials();

  useEffect(() => {
    loadAssociatedData();
  }, []);
  

  const fetchAssets = async () => {
    try {
      const response = await axiosWithCredentials.get('/fo/assets');
      setAssets(response.data);
    } catch (error) {
      console.error("Failed to load contacts:", error);
    }
  };

  const fetchLiabilities = async () => {
    try {
      const response = await axiosWithCredentials.get('/fo/liabilities/info');
      setLiabilities(response.data);
    } catch (error) {
      console.error("Failed to load contacts:", error);
    }
  };


  const fetchContacts = async () => {
    try {
      const response = await axiosWithCredentials.get('/fo/contacts');
      console.log('response', response);
      setContacts(response.data);
    } catch (error) {
      console.error("Failed to load contacts:", error);
    }
  };

  const loadAssociatedData = () => {
    fetchContacts();
    fetchLiabilities();
    fetchAssets();
  }
    
  const navigate = useNavigate();
  return (
    <Card className="card-dashboard">
      <HStack className="estate-planning-content">
        <NextStep setProgress={setProgress} loadAssociatedData={loadAssociatedData} />
        <Progress progress={Math.floor(progress)}/>
        <ProposalInbox />
      </HStack>
      <Stack className="your-vault-content" variant="start">
        <div className="your-vault">
          <Text variant="h4">Your Vault</Text>
        </div>
        <HStack variant="stretch">
          <EstateValueCard assets={assets} liabilities={liabilities} navigate={navigate} />
          <TeamViewer contacts={contacts} navigate={navigate} />
          <DocumentsViewer navigate={navigate}/>
        </HStack>
      </Stack>
    </Card>
  );
}

export default EstateDashboard;
